<template>
    <div class="order-detail__body">
        <!-- Main information -->
        <p class="order-detail__title">
            Main information
        </p>

        <div class="order-detail__body_content">
            <div class="order-detail__body_content_row">
                <div class="order-detail__body_content_row_title">
                    Order ID:
                </div>
                <div class="order-detail__body_content_row_data">
                    <div class="card_body__detail-counter report">
                        <span class="tool">
                            Copy to clipboard
                        </span>
                        <span
                            class="order-id-clipboard"
                            @click="copyOrderId(order.orderid)"
                        >
                            {{ order.orderid }}
                            <img
                                src="@/assets/img/svg/clipboard-two-papers.svg"
                                alt="clipboard"
                                width="18px"
                                height="18px"
                                class="card_body__detail-img"
                                style="margin-left: 10px"
                            >
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="order.linked_to_order"
                class="order-detail__body_content_row"
            >
                <div class="order-detail__body_content_row_title">
                    Extra pages for order ID:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.linked_to_order }}
                </div>
            </div>
            <div
                v-if="order.orderid_batch_id"
                class="order-detail__body_content_row"
            >
                <div class="order-detail__body_content_row_title">
                    Transaction ID:
                </div>
                <div class="order-detail__body_content_row_data">
                    <router-link
                        v-if="order.orderid_batch_id"
                        :to="{
                            name: 'batch',
                            params: {
                                id: order.orderid_batch_id
                            }
                        }"
                    >
                        {{ order.orderid_batch_id }}
                    </router-link>
                </div>
            </div>
            <div class="order-detail__body_content_row">
                <div class="order-detail__body_content_row_title">
                    Ordered:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.created_at | moment_full_text }}
                </div>
            </div>
            <div class="order-detail__body_content_row">
                <div class="order-detail__body_content_row_title">
                    Due:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.due_at | moment_full_text }}
                </div>
            </div>
            <div class="order-detail__body_content_row">
                <div class="order-detail__body_content_row_title">
                    Title:
                </div>
                <div class="order-detail__body_content_row_data">
                    «{{ order.project_information.title }}»
                </div>
            </div>
        </div>

        <!-- Extra services -->
        <p
            v-if="order.project_information.preferred_writers.length || order.project_information.blocked_writers.length"
            class="order-detail__title"
        >
            Extra services
        </p>

        <div class="order-detail__body_content">
            <preferred-writers
                :writers="order.project_information.preferred_writers"
                :title="'Preferred writer:'"
                :icon="'Preferred'"
            />
            <preferred-writers
                :writers="order.project_information.blocked_writers"
                :title="'Blocked writer:'"
                :icon="'Blocked'"
            />
        </div>

        <!-- Project requirements -->
        <p class="order-detail__title">
            Project requirements:
        </p>

        <div class="order-detail__body_content">
            <div class="order-detail__body_content_row">
                <div class="order-detail__body_content_row_title">
                    Project:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.project_information.type }}
                </div>
            </div>
            <div
                v-if="order.project_information.academiclevel"
                class="order-detail__body_content_row"
            >
                <div class="order-detail__body_content_row_title">
                    Level:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.project_information.academiclevel }}
                </div>
            </div>
            <div
                v-if="order.project_information.subject"
                class="order-detail__body_content_row"
            >
                <div class="order-detail__body_content_row_title">
                    Subject:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.project_information.subject }}
                </div>
            </div>
            <div
                class="order-detail__body_content_row"
                :class="{'order-detail__body_content_row--not-selected' : !order.project_information.style }"
            >
                <div class="order-detail__body_content_row_title">
                    Citation Style:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.project_information.style ? order.project_information.style : 'No citation style selected' }}
                </div>
            </div>
            <div
                class="order-detail__body_content_row"
                :class="{'order-detail__body_content_row--not-selected' : !order.project_information.sources }"
            >
                <div class="order-detail__body_content_row_title">
                    Sources:
                </div>
                <div class="order-detail__body_content_row_data">
                    {{ order.project_information.sources ? order.project_information.sources : 0 }}
                </div>
            </div>
            <div
                v-if="order.chk_abstract || order.chk_outline"
                class="order-detail__body_content_row order-detail__body_content_row--column"
            >
                <div class="order-detail__body_content_row_title">
                    Assignment Details:
                </div>
                <div class="order-detail__body_content_row_data order-detail__body_content_row_data--column">
                    <p
                        v-if="order.chk_abstract"
                        class="p-tooltip"
                    >
                        <span> I need an abstract</span>
                        <span class="custom-tooltip">
                            <p>?</p>
                            <span>
                                It is a 150- to 250-word paragraph that provides readers with a quick overview of your essay or report and its organization.
                                It should express your thesis (or central idea) and your key points; it should also suggest any implications or applications of the research you discuss in the paper.
                            </span>
                        </span>
                    </p>
                    <p
                        v-if="order.chk_outline"
                        class="p-tooltip"
                    >
                        <span> I need an outline</span>
                        <span class="custom-tooltip">
                            <p>?</p>
                            <span>An outline allows a writer to categorize the main points, to organize the paragraphs into an order that makes sense, and to make sure that each paragraph/idea can be fully developed.
                                Essentially, an outline helps prevent a writer from getting stuck when performing the actual writing of the essay.</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <!-- Instructions -->
        <div class="order-detail__title">
            Instructions:
        </div>

        <div class="order-detail__body_content">
            <span v-html="details" />
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
// Icon
import { eventBus } from '@/helpers/event-bus/'
import PreferredWriters from '@/components/writers/PreferredWriters';

export default {
    components: {
        PreferredWriters
    },
    mixins: [
        filtersMixin
    ],
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        details() {
            const splitString = this.order?.project_information.details?.split('\n').join('<br>')
            return splitString
        },
        paymethods() {
            return {
                cc: 'Credit card',
                paypal: 'PayPal'
            }
        }
    },
    methods: {
        getPaymethod(method) {
            return this.paymethods[method] || method
        },
        currentWriter(id) {
            const item = this.writers.find((item) => +item.sw_id === +id)
            return item
        },
        copyOrderId(orderid) {
            navigator.clipboard.writeText(orderid)
            eventBus.$emit('showSnackBar', 'Copying to clipboard was successful!', 'success');
        }
    }
}
</script>
