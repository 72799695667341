<template>
    <span class="writer-additional-output">
        <span
            v-if="isWriterHidden"
            v-click-outside="сloseTooltipOutsideField"
            class="writer-additional-output__tool-writer"
            :class="applyScroll"
        >
            <span
                v-for="(writer, index) in writers.slice(5)"
                :key="index"
                class="writer-additional-output__wrapper"
            >
                <a
                    :href="'/writers/profile/' + writer.sw_id"
                    class="writer-additional-output__writer-link"
                >
                    <span class="writer-additional-output__index">{{ index +1 }}</span>
                    <avatar
                        :avatar-fallback="writer.avatar_fallback"
                        :avatar-url="writer.avatar_url"
                        :name="writer.name"
                    />
                    <div class="writer-additional-output__writer-info">
                        <div><b>{{ writer.name }}</b> {{ writer.sw_id }}</div>
                        <div class="writer-additional-output__writer-info-records">
                            <span style="white-space: nowrap;">
                                <b>{{ writer.statistics.orders_total | number }}</b> projects
                            </span>
                            <span style="white-space: nowrap;">
                                <b>{{ writer.statistics.rank_all | percentWriterRank }}</b> success rate
                            </span>
                            <span style="white-space: nowrap;">
                                based on <b>{{ writer.statistics.rank_all_count | number }}</b> reviews
                            </span>
                        </div>
                    </div>
                </a>
            </span>
        </span>
        <span
            v-if="writers.length > 5"
            class="writer-additional-output__count"
            @click="isShowHiddenWriters()"
        >
            +{{ writers.slice(5).length }}
        </span>
    </span>
</template>

<script>

import Avatar from '@/components/common/Avatar.vue'
import filtersMixin from '@/mixins/filtersMixin'

export default {
    name: 'PreferredWriters',
    components: {
        Avatar
    },
    mixins: [
        filtersMixin
    ],
    props: {
        writers: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isWriterHidden: false
        }
    },
    computed: {
        applyScroll() {
            if (this.writers.length > 15) {
                return 'tool-scroll'
            }
            return false
        }
    },
    methods: {
        isShowHiddenWriters() {
            this.isWriterHidden = !this.isWriterHidden
        },
        сloseTooltipOutsideField() {
            this.isWriterHidden = false
        }
    }
}
</script>

<style lang="scss">

.writer-additional-output{
    position: relative;
    &__tool-writer{
        background-color: rgba(42, 53, 71, 1);
        border-radius: 5px;
        padding: 15px;
        position: absolute;
        bottom: 40px;
        @media (max-width:576px) {
            right: -10px;
        }
        .avatar-img{
            width: 25px;
            height: 25px;
        }
    }
    &__wrapper{
        display: inline;
    }
    &__writer-link{
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid transparent;
        &:hover {
            border-radius: 4px;
            padding: 10px;
            border: 1px solid #ff690c;
        }
    }
    &__index {
        font-size: 10px;
        margin-right: 15px;
        color: #fff;
    }
    &__writer-info{
        margin-left: 15px;
        font-size: 12px;
        color: #fff;
        div {
            white-space: nowrap;
            line-height: 1.3;
        }
    }
    &__writer-info-records{
        color: #abb1bb;
        display: flex;
        flex-direction: column;
        span {
            @media (max-width:768px) {
                display: flex;
                flex-direction: column;
            }
        }
    }
    &__count{
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: rgba(255, 105, 12, 0.1);
        color: #ff690c;
        font-weight: 600;
        font-size: 11px;
        text-align: center;
        cursor: pointer;
        &:hover {
            border: 1px solid;
            color: #ff690c;
        }
    }
}
.tool-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px !important;
}

</style>
